import React from "react";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "src/store/store";
import { routes } from "src/router/routes";

import error404 from "../../imgs/error404.svg";
import array from "../../imgs/array.svg";

export default function NotFound() {
    const navigate = useNavigate();
    const { thema } = useAppSelector((state) => state.appConfig);

    return (
        <div className="error-wrap">
            <div className="error404-block">
                <img src={error404} alt="Error 404" />
            </div>
            <h1 className="text-center mt-3" style={{ color: thema === "white" ? "black" : "#6c757d" }}>
                Page not found
            </h1>
            <div className="text-error404" style={{ color: thema === "white" ? "black" : "#6c757d" }}>
                Oops! Looks like you followed a incorect link.
            </div>
            <button
                onClick={() => navigate(`/${routes.dashboard}`)}
                type="button"
                className="btn btn-dark mt-4 d-flex gap-2 align-items-center"
                style={{ minWidth: "152px" }}
            >
                <img style={{ transform: "rotate(90deg)" }} src={array} alt="" />
                <span>Go back to home</span>
            </button>
        </div>
    );
}
