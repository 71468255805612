import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "src/store/store";
import { getProjectOrders } from "src/store/orders/actions";

import Pagination from "src/components/common/Pagination";
import ProjectOrder from "src/components/ProjectOrder";
import Loader from "src/components/common/Loader";

const Orders = () => {
    const dispatch = useAppDispatch();
    const { error, isOrdersLoaded, orders, total } = useAppSelector((state) => state.orders);
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get("page") || "1"));

    useEffect(() => {
        dispatch(getProjectOrders(currentPage));
    }, [currentPage]);

    const handlerPageClick = (page: number) => {
        setSearchParams(`page=${page}`);
        setCurrentPage(page);
    };

    const ordersJSX = useMemo(
        () =>
            !orders || !orders.length ? (
                <p className="fs-4 text-center my-5">You have not created any project yet</p>
            ) : (
                orders.map((order) => <ProjectOrder key={order.id} order={order} />)
            ),
        [orders],
    );

    if (!isOrdersLoaded) {
        return <Loader />;
    }

    if (error) {
        return <p>{error.message}</p>;
    }

    return (
        <div className="container py-2 px-4 mb-4">
            <h3 className="text-center my-3">Created orders</h3>
            {ordersJSX}
            <div className="mt-3 mb-5 d-flex justify-content-center">
                <Pagination totalItems={total} onPageClick={handlerPageClick} currentPage={currentPage} />
            </div>
        </div>
    );
};

export default Orders;
