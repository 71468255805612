import React, { useState } from "react";
import { Link } from "react-router-dom";

import { createProjectOrder } from "src/store/orders/orders.slice";
import { projectService } from "src/services/project.service";
import { ICreateProject, IProjectOrder } from "src/types";
import { useAppDispatch } from "src/store/store";
import { routes } from "src/router/routes";

import ModalContainer from "src/components/common/ModalContainer";
import CreateProjectForm from "src/components/CreateProjectForm";
import Loader from "src/components/common/Loader";
import styles from "./styles.module.scss";

export default function Project() {
    const [modalOpen, setModalOpen] = useState(false);
    const [createdOrder, setCreatedOrder] = useState<IProjectOrder | null>(null);
    const [createOrderLoading, setCreateOrderLoading] = useState(false);
    const dispatch = useAppDispatch();

    const handlerModalClose = () => setModalOpen(false);
    const handlerCreateFormSubmit = async (formData: ICreateProject) => {
        setCreateOrderLoading(true);
        const order = await projectService.createProject(formData);

        if (!order) {
            return;
        }

        setCreateOrderLoading(false);
        setCreatedOrder(order);
        dispatch(createProjectOrder(order));
        setModalOpen(true);
    };

    return (
        <div className="generate-pannel">
            <div className="fs-3 text-center mb-2">New Project</div>
            <div className={`${styles.formContainer} border rounded p-4 transition-05`}>
                {createOrderLoading ? <Loader /> : <CreateProjectForm handlerSubmit={handlerCreateFormSubmit} />}
                <ModalContainer
                    modalShow={modalOpen}
                    onClose={handlerModalClose}
                    title="Great news"
                    onSubmit={handlerModalClose}
                >
                    <div>
                        <p className="text-center">Your project with order #{createdOrder?.id} is in progress.</p>
                        <p className="text-center">
                            You can find out more if
                            <Link to={`/${routes.orders}`} className=" ms-2 text-primary">
                                Follow this link
                            </Link>
                        </p>
                    </div>
                </ModalContainer>
            </div>
        </div>
    );
}
