import React from "react";
import { Link } from "react-router-dom";

import { useAppSelector } from "src/store/store";
import { routes } from "src/router/routes";

export default function Header() {
    const { user } = useAppSelector((state) => state.profile);
    const { thema } = useAppSelector((state) => state.appConfig);

    return (
        <div className="header">
            <div></div>
            <div className="d-flex align-items-center gap-3" style={{ cursor: "pointer" }}>
                <Link to={`/${routes.profile}`}>
                    <span style={{ fontSize: "18px", color: thema === "white" ? "black" : "lightgray" }}>
                        {`${user?.firstName} ${user?.lastName}`}
                    </span>
                </Link>
            </div>
        </div>
    );
}
