import React from "react";

export enum NotificationType {
    ERROR = "error",
    SUCCESS = "success",
    INFO = "info",
}

interface IProps {
    type: NotificationType;
    message: string;
}

const Notification = ({ type, message }: IProps) => {
    const classList = ["alert", "alert-animation"];

    switch (type) {
        case NotificationType.SUCCESS:
            classList.push("alert-success");
            break;
        case NotificationType.ERROR:
            classList.push("alert-danger");
            break;
        default:
            classList.push("alert-info");
    }

    return (
        <div className={classList.join(" ")} role="alert">
            {message}
        </div>
    );
};

export default Notification;
