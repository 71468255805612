import axios, { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState, useCallback } from "react";
import { IGeoData } from "src/types";

const GEO_API = "https://ipapi.co/json";

const useGeo = () => {
    const [geoLoaded, setGeoLoaded] = useState(false);
    const [geoError, setGeoError] = useState<string | null>(null);
    const [geoData, setGeoData] = useState<IGeoData | null>(null);

    const getUserGeo = useCallback(async () => {
        try {
            const response: AxiosResponse<IGeoData> = await axios.get(GEO_API);
            setGeoData(response.data);
        } catch (error) {
            const err = error as AxiosError<string>;
            setGeoError(err.response ? err.response.statusText : err.message);
        } finally {
            setGeoLoaded(true);
        }
    }, [GEO_API]);

    useEffect(() => {
        getUserGeo();
    }, []);

    return {
        geoLoaded,
        geoError,
        geoData,
    };
};

export default useGeo;
