import React, { useMemo } from "react";
import Select from "react-select";

interface SelectedItem {
    label: string;
    value: string;
}

interface IProps<T> {
    dataArray: T[] | null;
    valueField: keyof T;
    mainLabel: keyof T;
    aditionalLabel?: keyof T;
    selectedItem: SelectedItem | null;
    onChange: React.Dispatch<React.SetStateAction<SelectedItem | null>>;
    className?: string;
    style?: object;
    id?: string;
}

export default function SearchSelect<T>({
    dataArray,
    valueField,
    mainLabel,
    aditionalLabel,
    selectedItem,
    onChange,
    id,
    className,
}: IProps<T>) {
    const transformedData = useMemo(() => {
        if (!dataArray || !dataArray.length) {
            return [];
        }

        return dataArray.map((item) => {
            return {
                value: item[valueField] as string,
                label: `${item[mainLabel]} ${aditionalLabel ? "(" + item[aditionalLabel] + ")" : ""}`,
            };
        });
    }, [dataArray]);

    return (
        <Select
            required
            options={transformedData}
            closeMenuOnSelect={true}
            onChange={onChange}
            value={selectedItem}
            className={className || undefined}
            id={id || undefined}
        />
    );
}
