export const toastConfig = {
    autoClose: 6000,
    position: "bottom-center" as const,
    hideProgressBar: false,
    newestOnTop: true,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    theme: "light" as const,
    rtl: false,
    limit: 3,
};
