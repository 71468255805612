import { useMemo } from "react";
import infoIcon from "../../../imgs/icons-info.svg";
import styles from "./styles.module.scss";
import { OverlayTrigger } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";

interface IProps {
    text: string;
}

export default function IconInfo({ text }: IProps) {
    const popover = useMemo(
        () => (
            <Popover id="popover-basic">
                <Popover.Header className={styles.popover__title} as="h3">
                    Usefull tooltip
                </Popover.Header>
                <Popover.Body>{text}</Popover.Body>
            </Popover>
        ),
        [text],
    );

    return (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} key="top" placement="right" overlay={popover}>
            <img src={infoIcon} alt="icon info" className={styles.icon_info} />
        </OverlayTrigger>
    );
}
