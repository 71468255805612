import React from "react";
import { Route, Routes } from "react-router-dom";

import DashboardLayout from "src/layout/DashboardLayout";
import PublicLayout from "src/layout/PublicLayout";
import AuthLayout from "src/layout/AuthLayout";

import EmailVerification from "src/pages/EmailVerification";
import ResetPassword from "src/pages/ResetPassword";
import Loader from "src/components/common/Loader";
import WelcomePage from "src/pages/WelcomePage";
import NotFound from "src/pages/NotFound";
import Project from "src/pages/Project";
import Profile from "src/pages/Profile";
import Orders from "src/pages/Orders";
import SignIn from "src/pages/SignIn";
import SignUp from "src/pages/SignUp";

import { routes } from "./routes";

export default function AppRouter() {
    const { auth, orders, profile, root, signIn, signUp, verify, dashboard, resetPassword } = routes;

    return (
        <div className="wrapper">
            <Routes>
                <Route path={root} loader={() => <Loader />}>
                    <Route path={root} element={<WelcomePage />} />
                    <Route path={auth} element={<PublicLayout />}>
                        <Route path={signIn} element={<SignIn />} />
                        <Route path={signUp} element={<SignUp />} />
                        <Route path={`${verify}/:token`} element={<EmailVerification />} />
                        <Route path={`${resetPassword}/:token`} element={<ResetPassword />} />
                    </Route>

                    <Route element={<AuthLayout />}>
                        <Route element={<DashboardLayout />}>
                            <Route path={dashboard} element={<Project />} />
                            <Route path={orders} element={<Orders />} />
                            <Route path={profile} element={<Profile />} />
                        </Route>
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </div>
    );
}
