import { useState } from "react";
import { NotificationType } from "src/components/Notification";

export interface INotification {
    display: boolean;
    type: NotificationType;
    text: string;
}

const INITIAL_NOTIFICATION: INotification = {
    display: false,
    type: NotificationType.ERROR,
    text: "Error login or password",
};

const useNotification = () => {
    const [notification, setNotification] = useState(INITIAL_NOTIFICATION);
    const resetNotification = () => setNotification(INITIAL_NOTIFICATION);

    return {
        notification,
        setNotification,
        resetNotification,
    };
};

export default useNotification;
