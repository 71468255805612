import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import Loader from "src/components/common/Loader";
import { routes } from "src/router/routes";
import useAuth from "src/hooks/useAuth";

const PublicLayout = () => {
    const { isLogin, loading } = useAuth();

    if (loading) {
        return <Loader />;
    }

    if (isLogin) {
        return <Navigate to={`/${routes.dashboard}`} replace={true} />;
    }

    return <Outlet />;
};

export default PublicLayout;
