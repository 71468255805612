import React, { useState } from "react";

import { INotification } from "src/hooks/useNotification";
import { useAppSelector } from "src/store/store";

import eyeSlash from "../../imgs/eye-slash.svg";
import Notification from "../Notification";
import mailsvg from "../../imgs/mail.svg";
import eye from "../../imgs/eye.svg";

interface IProps {
    loginHandler: React.FormEventHandler;
    notification: INotification;
    form: { email: string; password: string };
    onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const SignInForm = ({ loginHandler, notification, form, onChange }: IProps) => {
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const { thema } = useAppSelector((state) => state.appConfig);

    return (
        <>
            <h2 className="title-login">Please login</h2>
            <form onSubmit={loginHandler}>
                {notification.display && <Notification type={notification.type} message={notification.text} />}
                <div className="form-outline mb-4">
                    <label className="form-label d-flex gap-2 align-items-center" htmlFor="LoginMail">
                        <img src={mailsvg} alt="" />
                        <div>Email address</div>
                    </label>
                    <input
                        required
                        value={form.email}
                        onChange={onChange}
                        name="email"
                        placeholder="example@company.com"
                        type="email"
                        className={`form-control ${thema === "white" ? "" : "border-secondary"}`}
                        style={{
                            transitionDuration: "0.5s",
                            background: thema === "white" ? "#f8f9fa" : "rgb(39,40,50)",
                            color: thema === "white" ? "black" : "#6c757d",
                        }}
                    />
                </div>

                <div className="form-outline mb-2">
                    <label className="form-label d-flex gap-2 align-items-center" htmlFor="passwordLogin">
                        <img
                            onClick={() => setPasswordVisible(!isPasswordVisible)}
                            style={{ cursor: "pointer" }}
                            src={isPasswordVisible ? eye : eyeSlash}
                            alt=""
                        />
                        <div>Password</div>
                    </label>
                    <input
                        required
                        placeholder="password"
                        onChange={onChange}
                        value={form.password}
                        name="password"
                        type={isPasswordVisible ? "text" : "password"}
                        id="passwordLogin"
                        className={`form-control ${thema === "white" ? "" : "border-secondary"}`}
                        style={{
                            transitionDuration: "0.5s",
                            background: thema === "white" ? "#f8f9fa" : "rgb(39,40,50)",
                            color: thema === "white" ? "black" : "#6c757d",
                        }}
                    />
                </div>
                <button
                    style={{ color: thema === "white" ? "white" : "#9FA6B2" }}
                    className="btn w-100 btn-dark btn-block mb-4 mt-3"
                    type="submit"
                >
                    Sign in
                </button>
            </form>
        </>
    );
};

export default SignInForm;
