import React from "react";
import { Outlet, Navigate } from "react-router-dom";

import { toastNotification } from "src/components/common/ToastNotification";
import Loader from "src/components/common/Loader";
import { routes } from "src/router/routes";
import useAuth from "src/hooks/useAuth";

const AuthLayout = () => {
    const { error, isLogin, loading } = useAuth();

    if (loading) {
        return <Loader />;
    }

    if (error || !isLogin) {
        toastNotification(error, "error");

        return (
            <div>
                <p>{error}</p>
                <Navigate to={`/${routes.auth}/${routes.signIn}`} replace={true} />
            </div>
        );
    }

    return <Outlet />;
};

export default AuthLayout;
