import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import appConfigSlice from "./app.config.slice";
import ordersSlice from "./orders/orders.slice";
import profileSlice from "./profile.slice";

export const store = configureStore({
    reducer: {
        appConfig: appConfigSlice,
        profile: profileSlice,
        orders: ordersSlice,
    },
    devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
