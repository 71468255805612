import BootstrapPagination from "react-bootstrap/Pagination";
import React, { useMemo } from "react";

interface IProps {
    totalItems: number;
    onPageClick: (page: number) => void;
    currentPage: number;
}

const ITEMS_PER_PAGE = 10;

const Pagination = ({ totalItems = 1, onPageClick, currentPage }: IProps) => {
    const pagesCount = useMemo(() => Math.ceil(totalItems / ITEMS_PER_PAGE), [totalItems]);

    const handlerPrevPage = () => {
        if (currentPage <= 1) {
            return;
        }

        return onPageClick(--currentPage);
    };

    const handlerNextPage = () => {
        if (currentPage >= pagesCount) {
            return;
        }

        return onPageClick(++currentPage);
    };

    if (!pagesCount) {
        return null;
    }

    return (
        <BootstrapPagination>
            <BootstrapPagination.Prev onClick={handlerPrevPage} />
            {new Array(pagesCount).fill(null).map((_, index) => {
                const value = index + 1;

                return (
                    <BootstrapPagination.Item
                        key={value}
                        value={value}
                        onClick={() => onPageClick(value)}
                        active={currentPage === value}
                    >
                        {value}
                    </BootstrapPagination.Item>
                );
            })}

            <BootstrapPagination.Next onClick={handlerNextPage} />
        </BootstrapPagination>
    );
};

export default Pagination;
