import { AxiosError } from "axios";
import { useState, useEffect } from "react";

export function useFetch<T>(fetcher: () => Promise<T>) {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<T | null>(null);

    const fetch = async () => {
        try {
            const response = await fetcher();

            setData(response);
            setError(null);
        } catch (error) {
            const err = error as AxiosError<string>;
            setError(err.response ? err.response.data : err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    return {
        data,
        loading,
        error,
    };
}
