import { AxiosError } from "axios";
import { useEffect, useState } from "react";

import { authService } from "src/services/auth.service";
import { userService } from "src/services/user.service";
import { setUser } from "src/store/profile.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";

const useAuth = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [isLogin, setIsLogin] = useState<boolean>(false);
    const { user } = useAppSelector((state) => state.profile);
    const dispatch = useAppDispatch();

    useEffect(() => {
        (async () => {
            try {
                const isAuthorized = await authService.login();

                if (!isAuthorized) {
                    throw Error("Auth failed");
                }

                if (!user) {
                    const userData = await userService.profile();
                    dispatch(setUser(userData));
                }

                setIsLogin(isAuthorized);
            } catch (error) {
                const err = error as AxiosError<string>;

                setError(err.response ? err.response.data : err.message);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return { loading, error, isLogin };
};

export default useAuth;
