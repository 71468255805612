import React, { useState } from "react";
import { Link } from "react-router-dom";

import { INotification } from "src/hooks/useNotification";
import { useAppSelector } from "src/store/store";
import { routes } from "src/router/routes";
import useForm from "src/hooks/useForm";
import { ICreateUser } from "src/types";

import eyeSlash from "../../imgs/eye-slash.svg";
import Notification from "../Notification";
import mailsvg from "../../imgs/mail.svg";
import smile from "../../imgs/smile.svg";
import eye from "../../imgs/eye.svg";

interface IProps {
    onSubmit: (form: ICreateUser) => void;
    notification: INotification;
}

const INITIAL_FORM = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    passwordConfirm: "",
};

const SignUpForm = ({ onSubmit, notification }: IProps) => {
    const { thema } = useAppSelector((state) => state.appConfig);
    const [isPassVisible, setPassVisible] = useState(false);
    const { form, onChange } = useForm(INITIAL_FORM);

    const handlerSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        return onSubmit(form);
    };

    return (
        <>
            <h2 className="title-login">Sign up form</h2>
            <form onSubmit={handlerSubmit}>
                {notification.display && <Notification type={notification.type} message={notification.text} />}

                <div className="form-outline mb-4">
                    <label className="form-label d-flex gap-2 align-items-center" htmlFor="RegisterMail">
                        <img src={mailsvg} alt="" />
                        <div>Email address</div>
                    </label>
                    <input
                        required
                        value={form.email}
                        placeholder="example@company.com"
                        onChange={onChange}
                        name="email"
                        type="email"
                        id="RegisterMail"
                        className={`form-control ${thema === "white" ? "" : "border-secondary"}`}
                        style={{
                            transitionDuration: "0.5s",
                            background: thema === "white" ? "#f8f9fa" : "rgb(39,40,50)",
                            color: thema === "white" ? "black" : "#6c757d",
                        }}
                    />
                </div>

                <div className="form-outline mb-4">
                    <label className="form-label d-flex gap-2 align-items-center" htmlFor="RegisterFName">
                        <img src={smile} alt="" />
                        <div>First Name</div>
                    </label>
                    <input
                        required
                        placeholder="Enter name"
                        name="firstName"
                        onChange={onChange}
                        value={form.firstName}
                        type="text"
                        id="RegisterFName"
                        className={`form-control ${thema === "white" ? "" : "border-secondary"}`}
                        style={{
                            transitionDuration: "0.5s",
                            background: thema === "white" ? "#f8f9fa" : "rgb(39,40,50)",
                            color: thema === "white" ? "black" : "#6c757d",
                        }}
                    />
                </div>

                <div className="form-outline mb-4">
                    <label className="form-label d-flex gap-2 align-items-center" htmlFor="RegisterLName">
                        <img src={smile} alt="" />
                        <div>Last Name</div>
                    </label>
                    <input
                        required
                        placeholder="Enter last name"
                        name="lastName"
                        onChange={onChange}
                        value={form.lastName}
                        type="text"
                        id="RegisterLName"
                        className={`form-control ${thema === "white" ? "" : "border-secondary"}`}
                        style={{
                            transitionDuration: "0.5s",
                            background: thema === "white" ? "#f8f9fa" : "rgb(39,40,50)",
                            color: thema === "white" ? "black" : "#6c757d",
                        }}
                    />
                </div>

                <div className="form-outline mb-4">
                    <label className="form-label d-flex gap-2 align-items-center" htmlFor="RegisterPassword">
                        <img
                            src={isPassVisible ? eye : eyeSlash}
                            onClick={() => setPassVisible(!isPassVisible)}
                            style={{ cursor: "pointer" }}
                            alt=""
                        />
                        <div>Password</div>
                    </label>
                    <input
                        required
                        placeholder="Your password"
                        onChange={onChange}
                        name="password"
                        type={isPassVisible ? "text" : "password"}
                        id="RegisterPassword"
                        value={form.password}
                        className={`form-control ${thema === "white" ? "" : "border-secondary"}`}
                        style={{
                            transitionDuration: "0.5s",
                            background: thema === "white" ? "#f8f9fa" : "rgb(39,40,50)",
                            color: thema === "white" ? "black" : "#6c757d",
                        }}
                    />
                </div>

                <div className="form-outline mb-2">
                    <label className="form-label d-flex gap-2 align-items-center" htmlFor="RepeatPassword">
                        <img
                            src={isPassVisible ? eye : eyeSlash}
                            onClick={() => setPassVisible(!isPassVisible)}
                            style={{ cursor: "pointer" }}
                            alt=""
                        />
                        <div>Repeat password</div>
                    </label>
                    <input
                        required
                        placeholder="Confirm your password"
                        onChange={onChange}
                        name="passwordConfirm"
                        type={isPassVisible ? "text" : "password"}
                        id="RepeatPassword"
                        value={form.passwordConfirm}
                        className={`form-control ${thema === "white" ? "" : "border-secondary"}`}
                        style={{
                            transitionDuration: "0.5s",
                            background: thema === "white" ? "#f8f9fa" : "rgb(39,40,50)",
                            color: thema === "white" ? "black" : "#6c757d",
                        }}
                    />
                </div>
                <button
                    type="submit"
                    style={{ color: thema === "white" ? "white" : "#9FA6B2" }}
                    className="btn w-100 btn-dark btn-block mb-4 mt-3"
                >
                    Sign up
                </button>

                <div className="text-center">
                    <p>
                        Already have an account?{" "}
                        <Link to={`/${routes.auth}/${routes.signIn}`}>
                            <span style={{ color: thema === "white" ? "black" : "#6c757d" }}>Sign In</span>
                        </Link>
                    </p>
                </div>
            </form>
        </>
    );
};

export default SignUpForm;
