import React from "react";
import { Link } from "react-router-dom";

import { routes } from "src/router/routes";

import NavBarMenu from "./NavMenu";

import styles from "./styles.module.scss";

interface IProps {
    thema: string;
}

export default function NavbarDesktop({ thema }: IProps) {
    return (
        <div className={`nav-menu ${styles.navbar}`}>
            <div className={styles.navbar_sticky}>
                <div className="nav-title" style={{ color: thema === "white" ? "white" : "#E1DFDF" }}>
                    <Link className="text-white" to={`/${routes.dashboard}`}>
                        Wizzard
                    </Link>
                    <NavBarMenu thema={thema} />
                </div>
            </div>
        </div>
    );
}
