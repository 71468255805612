import React, { useState } from "react";

export function useForm<T>(initialForm: T) {
    const [form, setForm] = useState(initialForm);

    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setForm((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    const resetForm = () => {
        setForm(initialForm);
    };

    return {
        form,
        onChange,
        resetForm,
    };
}

export default useForm;
