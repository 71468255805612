import React, { useState } from "react";

import { dataProcessService } from "src/services/data.process.service";
import { useAppSelector } from "src/store/store";
import { websiteTypes } from "./website.types";
import { useFetch } from "src/hooks/useFetch";
import { ICreateProject } from "src/types";
import useForm from "src/hooks/useForm";

import { toastNotification } from "../common/ToastNotification";
import SearchSelect from "../common/SearchSelect";
import IconInfo from "../common/IconInfo";
import Button from "../common/Button";
import Loader from "../common/Loader";

import generate from "../../imgs/generate.svg";
import archive from "../../imgs/archive.svg";
import { checkLength } from "src/helpers/input.validation";

interface IProps {
    handlerSubmit: (params: ICreateProject) => Promise<void>;
}

interface ISelectedItem {
    value: string;
    label: string;
}

const INITIAL_FORM = {
    siteTheme: "",
    siteName: "",
    siteDescription: "",
    siteKeywords: "",
};

const CreateProjectForm = ({ handlerSubmit }: IProps) => {
    const { thema } = useAppSelector((state) => state.appConfig);
    const { form, onChange, resetForm } = useForm(INITIAL_FORM);

    const [selectedCountry, setSelectedCountry] = useState<ISelectedItem | null>(null);
    const [selectedLanguage, setSelectedLanguage] = useState<ISelectedItem | null>(null);
    const [selectedCategory, setSelectedCategory] = useState<ISelectedItem | null>(null);
    const [selectedWebsiteType, setSelectedWebsiteType] = useState<ISelectedItem | null>(null);

    const {
        loading: languageLoading,
        error: languageError,
        data: languages,
    } = useFetch(dataProcessService.languages.bind(dataProcessService));

    const {
        loading: countriesLoading,
        error: countriesError,
        data: countries,
    } = useFetch(dataProcessService.countries.bind(dataProcessService));

    const {
        loading: categoriesLoading,
        error: categoriesError,
        data: categories,
    } = useFetch(dataProcessService.projectCategories.bind(dataProcessService));

    const formSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const THEME_MIN_LENGTH = 200;
        const FIELD_MIN_LENGTH = 3;

        try {
            if (!selectedCountry || !selectedLanguage || !selectedCategory || !selectedWebsiteType) {
                throw Error("Please don't leave empty fields");
            }

            Object.entries(form).forEach((entry) => {
                if (!checkLength(entry[1], FIELD_MIN_LENGTH)) {
                    throw Error("Please fill in all form fields");
                }
            });

            if (!checkLength(form.siteTheme, THEME_MIN_LENGTH)) {
                throw Error(
                    `Website topic is too short. 
                    Provide please 2-3 sentances with at least ${THEME_MIN_LENGTH} symbols`,
                );
            }

            await handlerSubmit({
                ...form,
                country: selectedCountry.value,
                language: selectedLanguage.value,
                category: selectedCategory.value,
                projectType: selectedWebsiteType.value,
            });

            formReset();
        } catch (error) {
            const err = error as Error;
            toastNotification(err.message, "warning");
            return;
        }
    };

    const formReset = () => {
        resetForm();
        setSelectedCountry(null);
        setSelectedLanguage(null);
        setSelectedCategory(null);
    };

    const renderStages = (loading: boolean, error: null | string, element: React.ReactElement) => {
        if (loading) {
            return <Loader />;
        }

        if (error) {
            return <p>{error}</p>;
        }

        return element;
    };

    return (
        <form onSubmit={formSubmit} onReset={formReset}>
            <div className="form-outline w-100 input-group-lg mb-4">
                <label style={{ color: thema === "white" ? "black" : "#6c757d" }} htmlFor="topic">
                    Website topic*
                </label>
                <IconInfo
                    text={`We strongly recommend you include at least a few sentences to describe 
                    the general theme of the site, what it is for, and what services it provides. 
                    The more complete description, the better will be content`}
                />
                <input
                    required
                    name="siteTheme"
                    id="topic"
                    value={form.siteTheme}
                    onChange={onChange}
                    placeholder="Create description in 2-3 sentances of the idea for a new website"
                    type="text"
                    className={`form-control ${thema === "white" ? "" : "border-secondary"}`}
                    style={{
                        transitionDuration: "0.5s",
                        background: thema === "white" ? "#f8f9fa" : "rgb(39,40,50)",
                        color: thema === "white" ? "black" : "#6c757d",
                    }}
                />
            </div>

            <div className="inputs-container">
                <div className="form-outline w-100 input-group-lg mb-4">
                    <label style={{ color: thema === "white" ? "black" : "#6c757d" }} htmlFor="title">
                        Website title*
                    </label>
                    <IconInfo text="The title of you company or website, it will be used around the website" />
                    <input
                        required
                        name="siteName"
                        id="title"
                        value={form.siteName}
                        onChange={onChange}
                        placeholder="Title of new website"
                        type="text"
                        className={`form-control ${thema === "white" ? "" : "border-secondary"}`}
                        style={{
                            transitionDuration: "0.5s",
                            background: thema === "white" ? "#f8f9fa" : "rgb(39,40,50)",
                            color: thema === "white" ? "black" : "#6c757d",
                        }}
                    />
                </div>

                <div className="form-outline w-100 input-group-lg mb-4">
                    <label style={{ color: thema === "white" ? "black" : "#6c757d" }} htmlFor="language">
                        Content category*
                    </label>
                    <IconInfo text="Choose the most suitable category for new website" />
                    {renderStages(
                        categoriesLoading,
                        categoriesError,
                        <SearchSelect
                            dataArray={categories}
                            mainLabel="title"
                            valueField="title"
                            selectedItem={selectedCategory}
                            onChange={setSelectedCategory}
                        />,
                    )}
                </div>
            </div>
            <div className="inputs-container">
                <div className="form-outline w-100 input-group-lg mb-4">
                    <label style={{ color: thema === "white" ? "black" : "#6c757d" }} htmlFor="language">
                        Website language*
                    </label>
                    <IconInfo text="In which language we have to translate all content of new website" />
                    {renderStages(
                        languageLoading,
                        languageError,
                        <SearchSelect
                            dataArray={languages}
                            mainLabel="name"
                            valueField="name"
                            aditionalLabel="code"
                            selectedItem={selectedLanguage}
                            onChange={setSelectedLanguage}
                        />,
                    )}
                </div>
                <div className="form-outline w-100 input-group-lg mb-4">
                    <label style={{ color: thema === "white" ? "black" : "#6c757d" }}>Geo country*</label>
                    <IconInfo
                        text={`Choose geolocation of you company office. 
                            We will add more details like address, city, email`}
                    />
                    {renderStages(
                        countriesLoading,
                        countriesError,
                        <SearchSelect
                            dataArray={countries}
                            mainLabel="name"
                            valueField="name"
                            aditionalLabel="iso2"
                            selectedItem={selectedCountry}
                            onChange={setSelectedCountry}
                        />,
                    )}
                </div>
            </div>

            <div className="inputs-container">
                <div className="form-outline w-100 input-group-lg mb-4">
                    <label style={{ color: thema === "white" ? "black" : "#6c757d" }}>Website type*</label>
                    <IconInfo text="We can offer several types of websites. More options will be added in the future" />
                    <SearchSelect
                        dataArray={websiteTypes}
                        mainLabel="label"
                        valueField="name"
                        selectedItem={selectedWebsiteType}
                        onChange={setSelectedWebsiteType}
                    />
                </div>

                <div className="form-outline w-100 input-group-lg mb-4">
                    <label style={{ color: thema === "white" ? "black" : "#6c757d" }} htmlFor="keywords">
                        SEO Keywords*
                    </label>
                    <IconInfo text="Type basic keywords for SEO optimization, we will add more" />
                    <textarea
                        required
                        name="siteKeywords"
                        id="keywords"
                        value={form.siteKeywords}
                        onChange={onChange}
                        placeholder="Put here a few keywords, we will add more"
                        className={`form-control ${thema === "white" ? "" : "border-secondary"}`}
                        style={{
                            transitionDuration: "0.5s",
                            background: thema === "white" ? "#f8f9fa" : "rgb(39,40,50)",
                            color: thema === "white" ? "black" : "#6c757d",
                        }}
                    />
                </div>
            </div>

            <div className="form-outline input-group-lg mb-4">
                <label style={{ color: thema === "white" ? "black" : "#6c757d" }} htmlFor="description">
                    SEO Description*
                </label>
                <IconInfo text="Write general description of new website for metatags. We will add more information" />
                <textarea
                    required
                    name="siteDescription"
                    id="description"
                    value={form.siteDescription}
                    onChange={onChange}
                    placeholder="Website metadata will be generated from this description"
                    className={`form-control ${thema === "white" ? "" : "border-secondary"}`}
                    style={{
                        transitionDuration: "0.5s",
                        background: thema === "white" ? "#f8f9fa" : "rgb(39,40,50)",
                        color: thema === "white" ? "black" : "#6c757d",
                    }}
                />
            </div>
            <div className=" d-flex justify-content-between align-items-center gap-3 flex-wrap ">
                <Button
                    onClick={() => {}}
                    type="submit"
                    text="Create"
                    color={"btn-info"}
                    colorText={"black"}
                    img={generate}
                />
                <Button
                    onClick={() => {}}
                    type="reset"
                    text="Clear"
                    color="btn-dark"
                    colorText={"white"}
                    img={archive}
                />
            </div>
        </form>
    );
};

export default CreateProjectForm;
