import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { AxiosError } from "axios";

import { useAppDispatch, useAppSelector } from "../../store/store";
import { authService } from "src/services/auth.service";
import useNotification from "src/hooks/useNotification";
import { setUser } from "src/store/profile.slice";
import { routes } from "src/router/routes";
import useForm from "src/hooks/useForm";

import SendMailModal from "src/components/SendMailModal";
import { NotificationType } from "src/components/Notification";
import SignInForm from "src/components/SignInForm";
import Loader from "src/components/common/Loader";
import singin from "../../imgs/singin.svg";
import styles from "./styles.module.scss";

const INITIAL_FORM = {
    email: "",
    password: "",
};

export default function SignIn() {
    const dispatch = useAppDispatch();
    const { form, onChange } = useForm(INITIAL_FORM);
    const { thema } = useAppSelector((state) => state.appConfig);
    const { notification, setNotification, resetNotification } = useNotification();
    const [isLoading, setLoading] = useState(false);

    const navigate = useNavigate();

    const loginHandler = async (event: React.FormEvent) => {
        event.preventDefault();

        resetNotification();
        setLoading(true);
        try {
            const res = await authService.signIn(form.email, form.password);
            dispatch(setUser(res));

            setNotification({
                display: true,
                type: NotificationType.SUCCESS,
                text: "Login success",
            });

            navigate(`/${routes.dashboard}`);
        } catch (error) {
            const err = error as AxiosError<string>;

            setNotification({
                display: true,
                type: NotificationType.ERROR,
                text: err.response ? err.response.data : err.message,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            className={styles.signIn}
            style={{
                background: thema === "white" ? "white" : "rgb(39, 40, 50)",
                color: thema === "white" ? "black" : "#6c757d",
            }}
        >
            <img src={singin} className="singin-img" alt="" />
            <div
                style={{
                    transitionDuration: "0.5s",
                    background: thema === "white" ? "#FBFBFB" : "rgb(24,25,35)",
                    color: thema === "white" ? "black" : "#6c757d",
                }}
                className={`border p-4 rounded p-sm-5 ${thema === "white" ? "" : "border-secondary"} ${
                    styles.signIn__form
                }`}
            >
                <SignInForm loginHandler={loginHandler} notification={notification} form={form} onChange={onChange} />
                {isLoading && <Loader />}
                <div className="text-center">
                    <p>
                        Do not have an account?{" "}
                        <Link to={`/${routes.auth}/${routes.signUp}`}>
                            <span style={{ color: thema === "white" ? "black" : "#6c757d" }}>Sign up</span>
                        </Link>
                    </p>
                </div>

                <SendMailModal
                    title="Did not receive verification email?"
                    actionText="Resend email"
                    onSubmit={authService.resendEmailVerification.bind(authService)}
                />
                <SendMailModal
                    title="Forgot your password?"
                    actionText="Reset password"
                    onSubmit={authService.resetPasswordRequest.bind(authService)}
                />
            </div>
        </div>
    );
}
