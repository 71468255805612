import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";

import { routes } from "src/router/routes";

import NavBarMenu from "./NavMenu";

interface IProps {
    thema: string;
}

export default function NavbarMobile({ thema }: IProps) {
    const [isNavShow, setNavShow] = React.useState(false);

    return (
        <Navbar bg="light" className="nav-bar-mobile p-3 navbar-dark bg-dark" expand="lg" expanded={isNavShow}>
            <Navbar.Brand style={{ color: thema === "white" ? "white" : "#E1DFDF" }}>
                <Link className="text-white" to={`/${routes.dashboard}`}>
                    Wizzard
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                className="text-white"
                onClick={() => setNavShow(!isNavShow)}
            />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto mobile-nav-menu" style={{ paddingBottom: "0" }}>
                    <NavBarMenu thema={thema} />
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}
