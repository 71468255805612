import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUserResponse } from "src/types";

interface ProfileState {
    user: IUserResponse | null;
    loading: boolean;
    error: null | Error;
}

const initialState: ProfileState = {
    user: null,
    error: null,
    loading: false,
};

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<IUserResponse>) => {
            state.user = action.payload;
            state.loading = false;
            state.error = null;
        },
        clearUser: (state) => {
            state.loading = false;
            state.error = null;
            state.user = null;
        },
    },
});

export const { setUser, clearUser } = profileSlice.actions;

export default profileSlice.reducer;
