export const routes = {
    root: "/",
    auth: "auth",
    signIn: "sign-in",
    signUp: "sign-up",
    orders: "orders",
    profile: "profile",
    dashboard: "dashboard",
    verify: "verify-email",
    resetPassword: "reset-password",
};
