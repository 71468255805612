class LocalStorageService {
    getItem<T>(key: string): T | null {
        const data = localStorage.getItem(key);

        if (!data) {
            return null;
        }

        try {
            return JSON.parse(data);
        } catch (error) {
            return null;
        }
    }

    setItem<T>(key: string, value: T) {
        const jsonData = JSON.stringify(value);

        localStorage.setItem(key, jsonData);
    }
}

export const localStorageService = new LocalStorageService();
