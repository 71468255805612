import { PayloadAction, SerializedError, createSlice } from "@reduxjs/toolkit";

import { getProjectOrders } from "./actions";
import { IProjectOrder } from "src/types";

interface IOrderState {
    orders: IProjectOrder[];
    error: SerializedError | null;
    isOrdersLoaded: boolean;
    total: number;
    selectedOrder: null | IProjectOrder;
}

const initialState: IOrderState = {
    orders: [],
    total: 0,
    error: null,
    isOrdersLoaded: false,
    selectedOrder: null,
};

const ordersSlice = createSlice({
    name: "orders",
    initialState,
    reducers: {
        createProjectOrder: (state, action: PayloadAction<IProjectOrder>) => {
            state.orders.push(action.payload);
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(getProjectOrders.pending, (state) => {
                state.isOrdersLoaded = false;
                state.error = null;
                state.orders = [];
            })
            .addCase(getProjectOrders.fulfilled, (state, action) => {
                state.isOrdersLoaded = true;
                state.error = null;
                state.orders = action.payload.rows;
                state.total = action.payload.count;
            })
            .addCase(getProjectOrders.rejected, (state, action) => {
                state.isOrdersLoaded = true;
                state.error = action.error;
            }),
});

export const { createProjectOrder } = ordersSlice.actions;

export default ordersSlice.reducer;
