import React from "react";
import { AxiosError } from "axios";

import { useAppSelector, useAppDispatch } from "src/store/store";
import { UpdateUserForm } from "../../components/UpdateUserForm";
import { UpdatePasswordForm } from "../../components/UpdatePasswordForm";
import useForm from "src/hooks/useForm";
import { IUserResponse } from "src/types";
import { userService } from "src/services/user.service";
import { setUser } from "src/store/profile.slice";
import { toastNotification } from "src/components/common/ToastNotification";
import { checkLength } from "src/helpers/input.validation";

import styles from "./styles.module.scss";

const Profile = () => {
    const user = useAppSelector((state) => state.profile.user as IUserResponse);
    const { form, onChange } = useForm(user);

    const {
        form: passForm,
        onChange: changePass,
        resetForm: resetPasswordForm,
    } = useForm({
        currentPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
    });

    const dispatch = useAppDispatch();

    const handleNameChangeSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!checkLength(form.firstName, 2) || !checkLength(form.lastName, 2)) {
            toastNotification("Field cannot be left empty. Field must contain more than one character", "error");
            return;
        }

        try {
            const data = await userService.update(form);

            dispatch(setUser(data));
            toastNotification("Data has been updated", "success");
        } catch (err) {
            toastNotification("Error occurred", "error");
        }
    };

    const handlePassChangeSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            await userService.password(passForm);
            toastNotification("Password updated", "success");
            resetPasswordForm();
        } catch (err) {
            const error = err as AxiosError<string>;
            toastNotification(`Error ${error.response ? error.response.data : error.message}`, "warning");
        }
    };

    if (!user) {
        return <div>User data is not available</div>;
    }

    return (
        <div className="container py-2 px-4 mb-4">
            <h3 className="text-center my-3">Profile</h3>
            <div className={`${styles.profile} ${styles.container} card px-4 py-2`}>
                <p className={styles.profile__form__title}>Personal Data</p>

                <UpdateUserForm
                    styles={styles.profile__form}
                    handleNameChangeSubmit={handleNameChangeSubmit}
                    onChange={onChange}
                    form={form}
                />
                <p className={styles.profile__form__title}>Password</p>
                <UpdatePasswordForm
                    styles={styles.profile__form}
                    handlePassChangeSubmit={handlePassChangeSubmit}
                    onChange={changePass}
                    form={passForm}
                />
            </div>
        </div>
    );
};

export default Profile;
