import { useParams } from "react-router-dom";
import React from "react";

import Loader from "src/components/common/Loader";

import { authService } from "src/services/auth.service";
import { useFetch } from "src/hooks/useFetch";

const ResetPassword = () => {
    const params = useParams();

    const { error, loading } = useFetch(authService.resetPasswordComplete.bind(authService, params.token as string));

    if (loading) {
        return <Loader />;
    }

    const successContentJSX = (
        <div className="alert alert-success" role="alert">
            <h4 className="alert-heading">Password Reset Requested!</h4>
            <p>
                A request to reset your password has been successfully submitted. Please check your email for further
                instructions on how to reset your password.
            </p>
            <p className="mb-0">
                If you did not request a password reset, please ignore this message or contact support for assistance.
            </p>
        </div>
    );

    const errorContentJSX = (
        <div className="alert alert-warning" role="alert">
            <h4 className="alert-heading">Something goes wrong</h4>
            <p>{error}</p>
        </div>
    );

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-6 offset-md-3">{error ? errorContentJSX : successContentJSX}</div>
            </div>
        </div>
    );
};

export default ResetPassword;
