import { NavLink, useNavigate } from "react-router-dom";
import React from "react";

import { authService } from "src/services/auth.service";
import { clearUser } from "src/store/profile.slice";
import { useAppDispatch } from "src/store/store";
import { routes } from "src/router/routes";

import { toastNotification } from "../common/ToastNotification";
import generate from "../../imgs/lightbulb.svg";
import person from "../../imgs/person.svg";
import db from "../../imgs/database.svg";
import array from "../../imgs/array.svg";
import gear from "../../imgs/gear.svg";

interface IProps {
    thema: string;
}

type NavLinkRenderProps = {
    isActive: boolean;
    isPending: boolean;
    isTransitioning: boolean;
};

const NavbarMenu = ({ thema }: IProps) => {
    const [isSettingsShow, setSettingsShow] = React.useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const settingsHandler = () => {
        setSettingsShow(!isSettingsShow);
    };

    const handlerSignOut = async () => {
        try {
            await authService.signOut();
            dispatch(clearUser());
            navigate(`/${routes.auth}/${routes.signIn}`, { replace: true });
        } catch (err) {
            const error = err as Error;
            toastNotification(error.message, "warning");
        }
    };

    const getLinkStyles = ({ isActive }: NavLinkRenderProps) => ["nav-elem", isActive && "menu-clicked"].join(" ");

    return (
        <div className="mt-3">
            <NavLink to={`/${routes.dashboard}`} className={getLinkStyles}>
                <img src={generate} alt="" className="me-3" />
                <span style={{ color: thema === "white" ? "white" : "#E1DFDF" }}>Create website</span>
            </NavLink>
            <NavLink to={`/${routes.orders}`} className={getLinkStyles}>
                <img src={db} alt="" className="me-3" />
                <span style={{ color: thema === "white" ? "white" : "#E1DFDF" }}>Orders</span>
            </NavLink>
            <NavLink to={`/${routes.profile}`} className={getLinkStyles}>
                <img src={person} alt="" className="me-3" />
                <span style={{ color: thema === "white" ? "white" : "#E1DFDF" }}>Profile</span>
            </NavLink>

            <div className="settings-menu-container">
                <div
                    className={`nav-elem settings-menu-part justify-content-between ${false && "menu-clicked"}`}
                    onClick={settingsHandler}
                >
                    <div className="d-flex gap-2 align-items-center ">
                        <img src={gear} style={{ fill: "white" }} alt="" />
                        <span style={{ color: thema === "white" ? "white" : "#E1DFDF" }}>Settings</span>
                    </div>
                    <img
                        style={{ transform: isSettingsShow ? "" : "rotate(-90deg)", transitionDuration: "0.5s" }}
                        src={array}
                        alt=""
                    />
                </div>
                <div className={`settings-dd fs-6 ${isSettingsShow && "append-settings-dd"}`}>
                    <div onClick={handlerSignOut} className="settings-elem">
                        Sing out
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavbarMenu;
