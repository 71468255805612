import React, { useState } from "react";
import { AxiosError, AxiosResponse } from "axios";

import { isEmail } from "src/helpers/input.validation";
import useForm from "src/hooks/useForm";

import { toastNotification } from "../common/ToastNotification";
import ModalContainer from "../common/ModalContainer";
import styles from "./styles.module.scss";

interface IProps {
    onSubmit: (email: string) => Promise<AxiosResponse<boolean>>;
    title: string;
    actionText: string;
}

const SendMailModal = ({ onSubmit, actionText, title }: IProps) => {
    const { form, onChange, resetForm } = useForm({ email: "" });
    const [modalShow, setModalShow] = useState(false);

    const handlerResendMailClick = () => {
        setModalShow(true);
    };

    const onModalClose = () => {
        setModalShow(false);
    };

    const handlerSendEmail = async () => {
        const isEmailInput = isEmail(form.email);

        if (!isEmailInput) {
            toastNotification("Invalid email", "warning");
            return;
        }

        try {
            await onSubmit(form.email);
            toastNotification("Message was successfully sent", "success");
            resetForm();
            return onModalClose();
        } catch (error) {
            const err = error as AxiosError<string>;
            toastNotification(err.response ? err.response.data : err.message);
        }
    };

    return (
        <div>
            <div className="text-center">
                <p>
                    {title}
                    <span className={`ms-1 fw-bold ${styles.form__resend}`} onClick={handlerResendMailClick}>
                        {actionText}
                    </span>
                </p>
            </div>

            <ModalContainer
                modalShow={modalShow}
                onClose={onModalClose}
                onSubmit={handlerSendEmail}
                btnText="Send"
                title="Send email letter form"
            >
                <form className="w-100">
                    <label className="d-flex w-100 flex-column">
                        <p>Enter your email address</p>
                        <input
                            onChange={onChange}
                            value={form.email}
                            name="email"
                            className="w-100 py-1 px-3"
                            required
                            type="email"
                            placeholder="example@domain.com"
                        />
                    </label>
                </form>
            </ModalContainer>
        </div>
    );
};

export default SendMailModal;
