import React from "react";
import { Button, Modal } from "react-bootstrap";

interface IProps {
    modalShow: boolean;
    onClose: () => void;
    onSubmit: () => void;
    children: React.ReactElement;
    title?: string;
    btnText?: string;
}

const ModalContainer = ({ modalShow, onClose, children, title, onSubmit, btnText = "Ok" }: IProps) => {
    return (
        <Modal show={modalShow} onHide={onClose}>
            <Modal.Header closeButton>{title && <Modal.Title>{title}</Modal.Title>}</Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onSubmit}>
                    {btnText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalContainer;
