import axios, { AxiosResponse } from "axios";
import { IProjectOrderPagination } from "src/types";

class OrderService {
    private readonly ORDER_URL: string;

    constructor() {
        const baseUrl = process.env.REACT_APP_API_URL as string;
        this.ORDER_URL = `${baseUrl}/orders`;
    }

    async orders(page: number): Promise<IProjectOrderPagination> {
        const response: AxiosResponse<IProjectOrderPagination> = await axios.get(`${this.ORDER_URL}`, {
            withCredentials: true,
            params: {
                page,
            },
        });

        return response.data;
    }
}

export const orderService = new OrderService();
