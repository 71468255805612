import axios, { AxiosError, AxiosResponse } from "axios";
import { toastNotification } from "src/components/common/ToastNotification";
import { ICreateProject, IProjectOrder } from "src/types";

class ProjectService {
    private readonly PROJECT_URL: string;

    constructor() {
        const baseUrl = process.env.REACT_APP_API_URL as string;
        this.PROJECT_URL = `${baseUrl}/projects`;
    }

    async createProject(projectData: ICreateProject): Promise<IProjectOrder | null> {
        try {
            const response: AxiosResponse<IProjectOrder> = await axios.post(
                `${this.PROJECT_URL}/generate`,
                projectData,
                {
                    withCredentials: true,
                },
            );

            toastNotification(`Order ${projectData.siteName} created`);
            return response.data;
        } catch (error) {
            const err = error as AxiosError<string>;
            toastNotification(err.response ? err.response.data : err.message, "error");
            return null;
        }
    }

    async downloadProject(projectId: number): Promise<MediaSource | null> {
        try {
            const response: AxiosResponse<MediaSource> = await axios.get(`${this.PROJECT_URL}/download/${projectId}`, {
                withCredentials: true,
                responseType: "blob",
            });

            return response.data;
        } catch (error) {
            return null;
        }
    }
}

export const projectService = new ProjectService();
