import { IUserResponse } from "src/types";

import Button from "../../components/common/Button";

interface IUpdateUserFormProps {
    styles: string;
    handleNameChangeSubmit: (event: React.FormEvent) => Promise<void>;
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    form: IUserResponse;
}

export const UpdateUserForm: React.FC<IUpdateUserFormProps> = ({
    styles,
    handleNameChangeSubmit,
    onChange,
    form,
}): JSX.Element => {
    return (
        <form onSubmit={handleNameChangeSubmit} className={styles}>
            <div className="input-group-lg">
                <label htmlFor="userFirstName">First Name</label>
                <input
                    onChange={onChange}
                    type="text"
                    value={form.firstName}
                    id="userFirstName"
                    name="firstName"
                    placeholder="First Name"
                    className="form-control"
                />
            </div>

            <div className="input-group-lg">
                <label htmlFor="userLastName">Last Name</label>
                <input
                    onChange={onChange}
                    type="text"
                    value={form.lastName}
                    id="userLastName"
                    name="lastName"
                    placeholder="Last Name"
                    className="form-control"
                />
            </div>

            <Button onClick={() => {}} type="submit" text="Change name" color={"btn-info"} colorText={"black"} />
        </form>
    );
};
