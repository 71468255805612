import axios, { AxiosResponse } from "axios";
import { ICategory, ICountry, ILanguage } from "src/types";

class DataProcessService {
    private readonly DATA_URL: string;

    constructor() {
        const baseUrl = process.env.REACT_APP_API_URL as string;
        this.DATA_URL = `${baseUrl}/data-process`;
    }

    async countries(): Promise<ICountry[]> {
        const response: AxiosResponse<ICountry[]> = await axios.get(`${this.DATA_URL}/countries`, {
            withCredentials: true,
        });

        return response.data;
    }

    async languages(): Promise<ILanguage[]> {
        const response: AxiosResponse<ILanguage[]> = await axios.get(`${this.DATA_URL}/languages`, {
            withCredentials: true,
        });

        return response.data;
    }

    async projectCategories(): Promise<ICategory[]> {
        const response: AxiosResponse<ICategory[]> = await axios.get(`${this.DATA_URL}/project-categories`, {
            withCredentials: true,
        });

        return response.data;
    }
}

export const dataProcessService = new DataProcessService();
