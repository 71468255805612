import axios, { AxiosResponse } from "axios";
import { IUserResponse, IChengePass } from "src/types";

class UserService {
    private readonly USER_URL: string;

    constructor() {
        const baseUrl = process.env.REACT_APP_API_URL as string;
        this.USER_URL = `${baseUrl}/users`;
    }

    async profile(): Promise<IUserResponse> {
        const response: AxiosResponse<IUserResponse> = await axios.get(`${this.USER_URL}/profile`, {
            withCredentials: true,
        });

        return response.data;
    }

    async update(body: IUserResponse): Promise<IUserResponse> {
        const response: AxiosResponse<IUserResponse> = await axios.post(`${this.USER_URL}/update`, body, {
            withCredentials: true,
        });

        return response.data;
    }

    async password(body: IChengePass): Promise<IChengePass> {
        const response: AxiosResponse<IChengePass> = await axios.post(`${this.USER_URL}/password`, body, {
            withCredentials: true,
        });

        return response.data;
    }
}

export const userService = new UserService();
