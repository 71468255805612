import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { localStorageService } from "src/services/storage/local.storage.service";
import { localStorageKeys } from "src/services/storage/local.storage.keys";
import { IAppThema } from "src/types";

interface AppConfigState {
    thema: IAppThema;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    templateList: any[];
}

const initialState: AppConfigState = {
    thema: localStorageService.getItem(localStorageKeys.appThema) || "white",
    templateList: JSON.parse(localStorage.getItem("WpGeneratorTemplates") || "[]") || [],
};

export const appConfigSlice = createSlice({
    name: "app_config",
    initialState,
    reducers: {
        setThema: (state, action: PayloadAction<IAppThema>) => {
            state.thema = action.payload;
            localStorageService.setItem(localStorageKeys.appThema, action.payload);
        },
        // NEED REFACTORING
        addTemplate: (state, action) => {
            state.templateList = [...state.templateList, action.payload];
            localStorage.setItem("WpGeneratorTemplates", JSON.stringify(state.templateList));
        },
        removeTemplate: (state, action) => {
            state.templateList.splice(action.payload, 1);
            localStorage.setItem("WpGeneratorTemplates", JSON.stringify(state.templateList));
        },
    },
});

export const { setThema, addTemplate, removeTemplate } = appConfigSlice.actions;

export default appConfigSlice.reducer;
