import Button from "../../components/common/Button";

type PassForm = {
    currentPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
};

interface IUpdatePasswordFormProps {
    styles: string;
    handlePassChangeSubmit: (event: React.FormEvent) => Promise<void>;
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    form: PassForm;
}

export const UpdatePasswordForm = ({ styles, handlePassChangeSubmit, onChange, form }: IUpdatePasswordFormProps) => {
    return (
        <form onSubmit={handlePassChangeSubmit} className={styles}>
            <div className="input-group-lg">
                <label htmlFor="currPass">Current Password</label>
                <input
                    type="password"
                    value={form.currentPassword}
                    name="currentPassword"
                    id="currPass"
                    onChange={onChange}
                    placeholder="Current Password"
                    className="form-control"
                />
            </div>

            <div className="input-group-lg">
                <label htmlFor="newPass">New Password</label>
                <input
                    type="password"
                    value={form.newPassword}
                    name="newPassword"
                    id="newPass"
                    onChange={onChange}
                    placeholder="New Password"
                    className="form-control"
                />
            </div>

            <div className="input-group-lg">
                <label htmlFor="confirmPass">Confirm Password</label>
                <input
                    type="password"
                    value={form.newPasswordConfirm}
                    name="newPasswordConfirm"
                    id="confirmPass"
                    onChange={onChange}
                    placeholder="Confirm Password"
                    className="form-control"
                />
            </div>

            <Button onClick={() => {}} type="submit" text="Change password" color={"btn-info"} colorText={"black"} />
        </form>
    );
};
