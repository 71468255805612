import React, { useCallback, useEffect, useState } from "react";
import { AxiosError } from "axios";

import { isEmail, validatePassword } from "src/helpers/input.validation";
import { authService } from "src/services/auth.service";
import useNotification from "src/hooks/useNotification";
import { ICreateUser, IUserAnalytics } from "src/types";
import { useAppSelector } from "src/store/store";

import { NotificationType } from "src/components/Notification";
import SendMailModal from "src/components/SendMailModal";
import SignUpForm from "src/components/SignUpForm";
import Loader from "src/components/common/Loader";
import singin from "../../imgs/singin.svg";
import styles from "./styles.module.scss";
import useGeo from "src/hooks/useGeo";

const INITIAL_ANALYTICS: IUserAnalytics = {
    ip: null,
    location: null,
};

export default function SignUp() {
    const { thema } = useAppSelector((state) => state.appConfig);
    const { geoData, geoError, geoLoaded } = useGeo();
    const [userAnalytics, setUserAnalytics] = useState(INITIAL_ANALYTICS);
    const { notification, resetNotification, setNotification } = useNotification();
    const [isLoading, setLoading] = useState(false);

    const collectUserAnalytics = useCallback(() => {
        if (!geoLoaded) {
            return;
        }

        if (geoError) {
            setUserAnalytics({ ...userAnalytics, location: geoError });
            return;
        }

        if (!geoData) {
            return;
        }

        return setUserAnalytics({
            ip: geoData.ip,
            location: `${geoData.city}, ${geoData.region}, ${geoData.country_name}`,
        });
    }, []);

    useEffect(() => {
        collectUserAnalytics();
    }, [geoLoaded]);

    const registrationHandler = async (form: ICreateUser) => {
        resetNotification();
        const isValidMail = isEmail(form.email);
        if (!isValidMail) {
            setNotification({
                display: true,
                type: NotificationType.ERROR,
                text: "Invalid email address",
            });
            return;
        }

        if (form.password !== form.passwordConfirm) {
            setNotification({
                display: true,
                type: NotificationType.ERROR,
                text: "Passwords are different",
            });
            return;
        }

        const isPasswordValid = validatePassword(form.password);
        if (!isPasswordValid) {
            setNotification({
                display: true,
                type: NotificationType.ERROR,
                text: "Password is too weak",
            });
            return;
        }

        setLoading(true);

        try {
            const userData = {
                email: form.email,
                password: form.password,
                passwordConfirm: form.passwordConfirm,
                firstName: form.firstName,
                lastName: form.lastName,
                ip: userAnalytics.ip,
                location: userAnalytics.location,
            };

            await authService.signUp(userData);

            setNotification({
                display: true,
                type: NotificationType.SUCCESS,
                text: "Account has been created. To complete registration, please follow the link we sent you",
            });
        } catch (error) {
            const err = error as AxiosError<string>;

            setNotification({
                display: true,
                type: NotificationType.ERROR,
                text: err.response ? err.response.data : err.message,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.signUp}>
            <img src={singin} className="singin-img" alt="" />
            <div
                style={{
                    transitionDuration: "0.5s",
                    background: thema === "white" ? "#FBFBFB" : "rgb(24,25,35)",
                    color: thema === "white" ? "black" : "#6c757d",
                }}
                className={`border p-4 rounded p-sm-5 ${thema === "white" ? "" : "border-secondary"} ${
                    styles.signIn__form
                }`}
            >
                <SignUpForm onSubmit={registrationHandler} notification={notification} />
                {isLoading && <Loader />}
                <SendMailModal
                    title="Did not receive verification email?"
                    actionText="Resend email"
                    onSubmit={authService.resendEmailVerification.bind(authService)}
                />
            </div>
        </div>
    );
}
