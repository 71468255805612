export function isEmail(email: string) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
}

export const checkLength = (field: string, minLength: number) => {
    return field && field.trim() && field.length >= minLength;
};

export const validatePassword = (field: string) => {
    const MIN_LENGTH = 6;
    return checkLength(field, MIN_LENGTH);
};
