import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { toastNotification } from "src/components/common/ToastNotification";
import { orderService } from "src/services/order.service";
import { IProjectOrderPagination } from "src/types";

export const getProjectOrders = createAsyncThunk<IProjectOrderPagination, number>(
    "orders/all",
    async (page, { rejectWithValue }) => {
        try {
            const data = await orderService.orders(page);
            return data;
        } catch (error) {
            const err = error as AxiosError<string>;
            toastNotification(err.message, "error");
            throw rejectWithValue(err);
        }
    },
);
