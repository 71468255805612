import axios, { AxiosResponse } from "axios";
import { ICreateUserWithAnalytics, IUserResponse } from "src/types";

class AuthService {
    private readonly AUTH_URL: string;

    constructor() {
        const baseUrl = process.env.REACT_APP_API_URL as string;
        this.AUTH_URL = `${baseUrl}/auth`;
    }

    async signIn(email: string, password: string): Promise<IUserResponse> {
        const res = await axios.post(
            `${this.AUTH_URL}/sign-in`,
            {
                email,
                password,
            },
            { withCredentials: true },
        );

        return res.data;
    }

    async signUp(userData: ICreateUserWithAnalytics): Promise<AxiosResponse<IUserResponse>> {
        const res = await axios.post(`${this.AUTH_URL}/sign-up`, userData, { withCredentials: true });

        return res;
    }

    async login(): Promise<boolean> {
        return axios.get(`${this.AUTH_URL}/login`, { withCredentials: true });
    }

    async signOut(): Promise<AxiosResponse<null>> {
        return axios.get(`${this.AUTH_URL}/sign-out`, { withCredentials: true });
    }

    async verifyEmail(token: string): Promise<AxiosResponse<boolean>> {
        return axios.get(`${this.AUTH_URL}/verify-email/${token}`);
    }

    async resendEmailVerification(email: string): Promise<AxiosResponse<boolean>> {
        return axios.post(`${this.AUTH_URL}/resend-email`, { email });
    }

    async resetPasswordRequest(email: string): Promise<AxiosResponse<boolean>> {
        return axios.post(`${this.AUTH_URL}/reset-password`, { email });
    }

    async resetPasswordComplete(token: string): Promise<AxiosResponse<boolean>> {
        return axios.get(`${this.AUTH_URL}/reset-password/${token}`);
    }
}

export const authService = new AuthService();
