import React, { useMemo, useState } from "react";
import { Collapse } from "react-bootstrap";

import { toastNotification } from "../common/ToastNotification";
import { projectService } from "src/services/project.service";
import { dateFormat } from "src/helpers/date.format";
import { IProjectOrder } from "src/types";

interface IProps {
    order: IProjectOrder;
}

const successStatus = "success";

const ProjectOrder = ({ order }: IProps) => {
    const date = useMemo(() => dateFormat(order.createdAt), [order.createdAt]);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);

    const handlerOrderDetails = () => {
        setDetailsOpen(!detailsOpen);
    };

    const handlerDownloadClick = async () => {
        try {
            setDownloadLoading(true);

            if (!order.project || !order.project.archive) {
                throw Error();
            }

            const blobData = await projectService.downloadProject(order.project.id);

            if (!blobData) {
                throw Error();
            }

            const href = URL.createObjectURL(blobData);

            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", order.project.archive);
            document.body.appendChild(link);
            link.click();

            toastNotification("Download started", "success");

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        } catch (error) {
            toastNotification("Seems like project does not exist", "error");
        } finally {
            setDownloadLoading(false);
        }
    };

    const anchor = order.id.toString();

    const orderDetailsJSX = !order.project ? (
        <div className="text-center">Wait a second and reload page, project is not initialized yet</div>
    ) : (
        <div className="container flex-column flex-md-row d-flex flex-wrap">
            <div className="col-12 col-md-6">
                <p>
                    <b className="me-2">Topic:</b> {order.project.theme}
                </p>
                <p>
                    <b className="me-2">Website title:</b> {order.project.websiteTitle}
                </p>
            </div>

            <div className="col-12 col-md-6">
                <p>
                    <b className="me-2">Language:</b> {order.project.language}
                </p>
                <p>
                    <b className="me-2">Geo country:</b> {order.project.country}
                </p>
            </div>
        </div>
    );

    const downloadMessage = order.isDownloaded ? "Already downloaded" : "Not downloaded yet";

    return (
        <div className="card my-4">
            <div className="card-header">{date}</div>
            <div className="card-body">
                <h5 className="card-title">
                    {order.status} {order.status !== successStatus ? null : `| ${downloadMessage}`}
                </h5>
                {order.statusText ? <p className="card-text">Details: {order.statusText}</p> : null}
                <div className="d-flex gap-4 mt-4">
                    <button
                        className="btn btn-info"
                        data-toggle="collapse"
                        role="button"
                        aria-controls={anchor}
                        aria-expanded={detailsOpen}
                        onClick={handlerOrderDetails}
                    >
                        {detailsOpen ? "Hide details" : "Show details"}
                    </button>
                    {order.status === successStatus && (
                        <button className="btn btn-success" onClick={handlerDownloadClick}>
                            {downloadLoading ? "Please wait..." : "Download"}
                        </button>
                    )}
                </div>

                <Collapse in={detailsOpen}>
                    <div id={anchor} className="py-4">
                        {orderDetailsJSX}
                    </div>
                </Collapse>
            </div>
        </div>
    );
};

export default ProjectOrder;
