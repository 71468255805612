import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useRef, useMemo } from "react";

import { routes } from "src/router/routes";

import headerContentImg from "../../assets/img/welcome/header_content.png";
import westernImg from "../../assets/img/welcome/Western_Union.png";
import masterCardImg from "../../assets/img/welcome/MasterCard.png";
import nortonImg from "../../assets/img/welcome/norton_secure.png";
import letterImg from "../../assets/img/welcome/letter.svg";
import payPalImg from "../../assets/img/welcome/PayPal.png";
import dmcaImg from "../../assets/img/welcome/dmca.png";
import visaImg from "../../assets/img/welcome/Visa.png";
import logoImg from "../../assets/img/logo.svg";
import styles from "./styles.module.scss";

export default function WelcomePage() {
    const navigate = useNavigate();
    const getAuth = (endpoint: string) => {
        return `/${routes.auth}/${endpoint}`;
    };

    const year = useMemo(() => new Date().getFullYear(), []);

    const handlerStart = () => {
        navigate(getAuth(routes.signIn));
    };

    const aboutRef = useRef<HTMLDivElement | null>(null);
    const contactsRef = useRef<HTMLDivElement | null>(null);

    const handlerNavClick = (ref: React.MutableRefObject<HTMLDivElement | null>) => () => {
        ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    return (
        <div>
            <div className={`${styles.header} d-flex flex-column`}>
                <div className={styles.header__nav}>
                    <div className="container d-flex justify-content-between align-items-center py-3">
                        <NavLink to={routes.root} className="d-flex align-items-center gap-2 text-white">
                            <img src={logoImg} alt="app logo" className={styles.header__logo} />
                            <span className="d-none d-md-block">Wizzard</span>
                        </NavLink>
                        <div className="d-flex gap-2 gap-md-4">
                            <div className=" d-flex align-items-center">
                                <NavLink
                                    className="me-2 me-md-5 text-white"
                                    to={"#about"}
                                    onClick={handlerNavClick(aboutRef)}
                                >
                                    About
                                </NavLink>
                                <NavLink
                                    className="me-2 me-md-5 text-white"
                                    to={"#contact"}
                                    onClick={handlerNavClick(contactsRef)}
                                >
                                    Contact
                                </NavLink>
                            </div>
                            <div className="d-flex">
                                <NavLink
                                    className={`me-2 me-md-5 ${styles.btn_sm} ${styles.btn} ${styles.btn_outlined}`}
                                    to={getAuth(routes.signIn)}
                                >
                                    Login
                                </NavLink>
                                <NavLink
                                    className={`${styles.btn_sm} ${styles.btn} ${styles.btn_contained}`}
                                    to={getAuth(routes.signUp)}
                                >
                                    Sign Up
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`${styles.header__content} container d-flex`}
                    style={{ backgroundImage: `url(${headerContentImg})` }}
                >
                    <div className="pt-5 w-100 d-flex flex-column z-3 justify-content-start">
                        <div className="col-12 col-md-7">
                            <h1 className={`text-white mb-3 ${styles.header__title}`}>
                                Create Your Website in Just a Few Clicks
                            </h1>
                            <button onClick={handlerStart} className={`${styles.btn} ${styles.btn_contained}`}>
                                Generate Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.about} py-5`} id="about" ref={aboutRef}>
                <div className="container d-flex flex-column" id="about">
                    <div className="d-flex col-12 col-md-10 text-white my-5">
                        <div
                            className={`${styles.about__content} ${styles.about__content_end} ${styles.about__content_step_1}`}
                        >
                            <div className="col-8">
                                <h3 className={styles.about__header}>Make up an appealing theme for a website</h3>
                                <div className="w-50 d-flex flex-column align-items-start">
                                    <p className={styles.about__subtitle}>Site topic*</p>
                                    <div className={styles.about__input}>Create website topic description</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex col-12 col-md-10 text-white my-5 align-self-end flex-row-reverse">
                        <div
                            className={`${styles.about__content} ${styles.about__content_start} ${styles.about__content_step_2}`}
                        >
                            <div className="col-8 text-end d-flex flex-column align-items-end">
                                <h3 className={styles.about__header}>Create a unique name for your website</h3>
                                <div className="w-50 d-flex flex-column align-items-end">
                                    <p className={styles.about__subtitle}>Site title*</p>
                                    <div className={styles.about__input}>Title of new website</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex col-12 col-md-10  text-white my-5">
                        <div
                            className={`${styles.about__content} ${styles.about__content_end} ${styles.about__content_step_3}`}
                        >
                            <div className="col-8">
                                <h3 className={styles.about__header}>Select a content category for the website</h3>
                                <div className="w-50 d-flex flex-column align-items-start">
                                    <p className={styles.about__subtitle}>Website category*</p>
                                    <div className={styles.about__input}>Select</div>
                                    <ul
                                        className={`${styles.about__input} ${styles.inputList} ${styles.inputList__scroll_end} mt-3`}
                                    >
                                        <li className={styles.inputList__item}>Nature</li>
                                        <li className={`${styles.inputList__item} ${styles.selected_left}`}>
                                            Bysiness
                                        </li>
                                        <li className={styles.inputList__item}>Fasion</li>
                                        <li className={styles.inputList__item}>Music</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex col-12 col-md-10 text-white my-5 align-self-end flex-row-reverse">
                        <div
                            className={`${styles.about__content} ${styles.about__content_start} ${styles.about__content_step_4}`}
                        >
                            <div className="col-8 text-end d-flex flex-column align-items-end">
                                <h3 className={styles.about__header}>Choose the language of content for the website</h3>
                                <div className="w-50 d-flex flex-column align-items-end">
                                    <p className={styles.about__subtitle}>Site language*</p>
                                    <div className={styles.about__input}>Select</div>
                                    <ul
                                        className={`${styles.about__input} ${styles.inputList} ${styles.inputList__scroll_start} mt-3`}
                                    >
                                        <li className={styles.inputList__item}> English (UK)</li>
                                        <li className={styles.inputList__item}> German (GE)</li>
                                        <li className={`${styles.inputList__item} ${styles.selected_right}`}>
                                            Italian (IT)
                                        </li>
                                        <li className={styles.inputList__item}>Spanish (ESP)</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex col-12 col-md-10 text-white my-5">
                        <div
                            className={`${styles.about__content} ${styles.about__content_end} ${styles.about__content_step_5}`}
                        >
                            <div className="col-8">
                                <h3 className={styles.about__header}>
                                    Determine the geo location for the website's users
                                </h3>
                                <div className="w-50 d-flex flex-column align-items-start">
                                    <p className={styles.about__subtitle}>Geo country*</p>
                                    <div className={styles.about__input}>Select</div>
                                    <ul
                                        className={`${styles.about__input} ${styles.inputList} ${styles.inputList__scroll_end} mt-3`}
                                    >
                                        <li className={styles.inputList__item}>United Kingdom (UK)</li>
                                        <li className={`${styles.inputList__item} ${styles.selected_left}`}>
                                            Germany (GE)
                                        </li>
                                        <li className={styles.inputList__item}>Italy (IT)</li>
                                        <li className={styles.inputList__item}>Spain (ESP)</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex col-12 col-md-10 text-white my-5 align-self-end flex-row-reverse">
                        <div
                            className={`${styles.about__content} ${styles.about__content_start} ${styles.about__content_step_6}`}
                        >
                            <div className="col-8 text-end d-flex flex-column align-items-end">
                                <h3 className={styles.about__header}>Decide on the type of website to be created</h3>
                                <div className="w-50 d-flex flex-column align-items-end">
                                    <p className={styles.about__subtitle}>Website type*</p>
                                    <div className={styles.about__input}>Select</div>
                                    <ul className={`${styles.about__input} ${styles.inputList} mt-3 py-4`}>
                                        <li className={`${styles.inputList__item} ${styles.selected_right}`}>
                                            Landing Page
                                        </li>
                                        <li className={styles.inputList__item}>Multipages</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex col-12 col-md-10 text-white my-5">
                        <div
                            className={`${styles.about__content} ${styles.about__content_end} ${styles.about__content_step_7}`}
                        >
                            <div className="col-8">
                                <h3 className={styles.about__header}>
                                    Eventually, provide some data for SEO optimization
                                </h3>
                                <div className="w-50 d-flex flex-column align-items-start">
                                    <p className={styles.about__subtitle}>SEO Keywords/Description*</p>
                                    <div className={styles.about__input}>Add a few keywords</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 d-flex justify-content-center mt-5">
                        <div className="col-md-4 col-11">
                            <button
                                onClick={handlerStart}
                                className={`${styles.btn} ${styles.btn_contained} w-100 fs-5 fs-md-4`}
                            >
                                Generate Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.footer} py-5`}>
                <div className="container">
                    <div className="d-flex flex-column align-items-start">
                        <NavLink className="me-5 text-white" to={"#about"} onClick={handlerNavClick(aboutRef)}>
                            About
                        </NavLink>
                        <NavLink className="me-5 text-white" to={"#contact"} onClick={handlerNavClick(contactsRef)}>
                            Contact
                        </NavLink>

                        <div className="d-flex" ref={contactsRef}>
                            <label className={styles.email}>
                                <label htmlFor="e-mail">Email address</label>
                                <div className={styles.email__field}>
                                    <img src={letterImg} alt="letter icon" />
                                    <input
                                        className={styles.email__input}
                                        id="e-mail"
                                        type="text"
                                        placeholder="e-mail@gmail.com"
                                    />
                                </div>
                            </label>
                        </div>
                    </div>

                    <div className="d-flex flex-column flex-lg-row align-items-center justify-content-lg-between mt-3">
                        <div className="d-flex flex-wrap justify-content-around align-items-center gap-4 my-3">
                            <img className={styles.footer__logo} src={dmcaImg} alt="icon dmca" />
                            <img className={styles.footer__logo} src={nortonImg} alt="icon norton" />
                            <img className={styles.footer__logo} src={visaImg} alt="icon visa" />
                            <img className={styles.footer__logo} src={payPalImg} alt="icon PayPal" />
                            <img className={styles.footer__logo} src={masterCardImg} alt="icon Master Card" />
                            <img className={styles.footer__logo} src={westernImg} alt="icon Western Union" />
                        </div>

                        <div>
                            <p className="text-center">© {year} All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
