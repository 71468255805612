import React from "react";
import { Outlet } from "react-router-dom";

import { useAppSelector } from "src/store/store";

import NavbarDesktop from "src/components/Navbar/NavDesktop";
import NavbarMobile from "src/components/Navbar/NavMobile";
import Header from "src/components/Header";

import styles from "./styles.module.scss";

const DashboardLayout = () => {
    const { thema } = useAppSelector((state) => state.appConfig);

    return (
        <div>
            <NavbarMobile thema={thema} />
            <div className="d-flex">
                <NavbarDesktop thema={thema} />
                <div
                    className={`w-100 ${styles.dashboard__container} pb-4`}
                    style={{
                        background: thema === "white" ? "#f8f9fa" : "rgb(39,40,50)",
                    }}
                >
                    <Header />
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default DashboardLayout;
