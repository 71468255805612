import React from "react";
import { NavLink, Navigate, useParams } from "react-router-dom";

import { toastNotification } from "src/components/common/ToastNotification";
import Loader from "src/components/common/Loader";

import { authService } from "src/services/auth.service";
import { useFetch } from "src/hooks/useFetch";
import { routes } from "src/router/routes";

const EmailVerification = () => {
    const params = useParams();
    const token = params.token as string;
    const { auth, signIn } = routes;
    const signInUrl = `/${auth}/${signIn}`;

    const { error, loading } = useFetch(authService.verifyEmail.bind(authService, token));

    if (loading) {
        return (
            <div className="container text-center my-5">
                <Loader />
            </div>
        );
    }

    if (error) {
        return (
            <div className="container text-center my-5">
                <p className="text-center my-4"> {error}</p>
                <button className="btn btn-outline-primary px-4 py-2">
                    <NavLink to={signInUrl}> Return to sign in page</NavLink>
                </button>
            </div>
        );
    }

    toastNotification("Registration complete", "success");
    return (
        <div className="container">
            <h3 className="text-center"> Registration complete</h3>

            <Navigate to={signInUrl} />
        </div>
    );
};

export default EmailVerification;
