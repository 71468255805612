import React from "react";

interface IProps {
    onClick: () => void;
    color: string;
    text: string;
    img?: string;
    isDisabled?: boolean;
    colorText: string;
    type?: "button" | "submit" | "reset";
}

export default function Button({ onClick, color, text, img, isDisabled = false, colorText, type = "button" }: IProps) {
    return (
        <button
            disabled={isDisabled}
            onClick={onClick}
            type={type}
            className={`btn ${color} btn-block d-flex justify-content-center align-items-center gap-2`}
        >
            {img && <img src={img} alt="" />}
            <div style={{ color: colorText }}>{text}</div>
        </button>
    );
}
